import React from "react"
import Layout from "../components/layout"
import Sofa from "../../static/sofa.png"

export default function Home() {
  return (
    <Layout>

        <img src={Sofa} alt='' className='main-image'/>

      <div className='text-block' style={{flexWrap: `wrap`}}>
        <div style={{width: `320px`}}>
          <p className='main-title' style={{fontSize:`22px`, textAlign:`left`, marginTop:`0px`}}>
            APARTMÁNY LAFRANCONI
          </p>
          <p className='sub-title' style={{fontSize:`18px`, textAlign:`left`}}>
            Váš druhý domov
          </p>
        </div>
        <div className='text'>
          Apartmány Lafranconi sú situované v centrálnej časti Bratislavy, len 20 minút chôdze od Starého Mesta a 200 metrov od parku a promenády pri Dunaji.
        </div>

        <div style={{maxWidth: `720px`}}>
        Apartmány majú kompletne vybavenú kuchynku a vlastnú kúpeľňu so sprchovacím kútom. Hostia môžu bezplatne využívať práčku a žehličku. Wi-Fi pripojenie na internet je v celom apartmánovom dome a je taktiež bezplatné.
        <br/>
        V budove sa nachádza aj obchod s potravinami. Rôzne reštaurácie a bary sú od apartmánov Lafranconi vzdialené 5 minút chôdze.
        <br/>
        Letisko Bratislava je vzdialené 15 minút jazdy autom.
      </div>


      </div>
  

  

    </Layout>
  );
}